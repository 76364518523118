export default {
  toasts: {
    article: {
      view: {
        error: "Error",
        error_access: "You don't have access"
      }
    }
  },
  departments_tree: {
    tree: {
      clear_all_text: "Clear all",
      clear_value_text: "Clear value",
      loading_text: "Loading...",
      no_children_departments: "No sub departments",
      no_results: "No results",
      retry_text: "Retry?",
      search_prompt: "Type to search...",
    },
  },
  article: {
    edit: {
      data_type: {
        table: "Table",
        content: "Article",
      },
      statuses: {
        public: "Published",
        gray: "Rough draft",
        archive: "Archive",
      },
      types: {
        normal: "Article",
        snippet: {
          informer: "Informer",
          content: "Content",
        },
      },
      field: {
        name: "Article name",
        content: "Article content",
        preview: "Preview text",
        section: "Parent section",
        data_type: "Data type",
        table: "Excel",
        file: "Select file",
        browse: "Browse",
        type: "Type",
        status: "Status",
        responsible: "Responsible",
      },
      title: {
        tags: {
          article: "Tags",
          snippet: {
            informer: "Informer types",
            content: "Content types",
          },
        },
      },
    },
    snippet: {
      informer: {
        name: "Informer name",
        content: "Informer content",
      },
      content: {
        name: "Content name",
        content: "Content",
      },
      used: "Used",
    },
    title: {
      create: {
        article: "Create article",
        snippet: {
          informer: "Create informer",
          content: "Create content",
        },
      },
      edit: {
        article: "Edit article",
        snippet: {
          informer: "Edit informer",
          content: "Edit content",
        },
      },
      favorite_articles: "Featured articles",
      last_update_articles: "Last modified",
    },
    date: {
      open: "Openly inclusive",
      close: "Closed inclusive",
    },
    read: {
      new_head: "New",
      new_table: "New",
    },
    no_articles_categories:
      "There are no sections and articles in this space yet",
    view: {
      errors: {
        403: "You don't have access"
      }
    }
  },
  snippet: {
    informer: {
      name: "Informer name",
      content: "Informer content",
    },
    content: {
      name: "Content name",
      content: "Content's structure",
    },
  },
  comment: {
    mention: "@ Mention user",
    suggestion: {
      label: "Suggest a change to the article",
      result: {
        title: "Suggestion sent",
        message:
          "Responsible persons will be notified of the proposal to change this article!",
        message_thanks: "Thanks for the comment!",
      },
    },
  },
  search: {
    articles: "All results in articles",
    contents: "All results in contents",
    informers: "All results in informers",
    sections: "All results in sections",
    media: "All results in files",
    media_title: "Files",
    nothing_found: "No results found",
    surveys: "All results in surveys",
    include_in_result: "Include in the result:",
    by_sections: "By sections",
    strict: {
      any_form: "In any form",
      as_request: "Exactly as in the request",
    },
    nothind_found_long: "No results were found for this search",
  },
  hihub_service: {
    admin: "Administration",
  },
  support: {
    header: "Contact Knowledge Base support",
    select_recipients: "Select recipients",
    placeholder: "Start typing...",
    error_required: "This field is required",
    error_length: "Maximum length of message is 1000 characters",
    add_users: "Add users",
    error_count: "Select at least 1 recipient",
  },
  help: {
    header: "Help",
  },
  buttons: {
    ok: "OK",
    cancel: "Cancel",
    delete: "Delete",
    edit: "Edit",
    actions: "Actions",
    back: "Back",
    save: "Save",
    confirm: "Confirm",
    continue: "Continue",
    add: "Add",
    close: "Close",
  },
  roles: {
    admin: "Administrator",
    editor: "Editor",
    user: "User",
    creator: "Creator",
    not_specified: "Not specified",
  },
  workspace_users: {
    title: "Workspace users",
    table_headers: {
      name: "Name",
      last_name: "Last Name",
      email: "email",
      role: "Role",
    },

  },
  access: {
    modal: {
      title: {
        main: "Access settings",
        add: "Add permission\\prohibition",
      },
      form: {
        tabs: {
          user: "For user",
          department: "For department",
          workspace: "For role",
        },
        fields: {
          scope: "Workspace",
          role: "Role",
          permission: "Permission\\prohibition",
          user: "User",
          department: "Department",
        },
        descriptions: {
          scope: "The set rights will only work within the selected workspace",
          department: "The set rights will only work for the selected department",
          forbidden: {
            false: "Access on",
            true: "Forbidden on",
          },
          recursive: {
            false: "without subdepartments",
            true: "with subdepartments",
          },
        },
      },
      header: {
        workspace: "Workspace",
        role: "Scope",
        ability: "Access",
        role_entity: "Group of users",
        access: "Access",
      },
      workspace: {
        incorrect: "Incorrect workspace",
        denied: "Unknown workspace",
        root: "General workspace",
        unknown: "Workspace without name",
        unknown_id: "[{id}] Workspace without name",
      },
      department: {
        denied: "Unknown department",
      },
      tooltip: {
        denied: "Permission\\prohibition can't be deleted with current user",
        forbidden: {
          false: "Allowed",
          true: "Prohibition",
        },
      },
      buttons: {
        delete: "Delete",
        restore: "Cancel",
      },
      ability: {
        "*": "Full access",
        read: "view",
        write: "Edit",
        full: "Full access",
        denied: "Access denied",
      },
      role: {
        role: "Role",
        by_type: {
          workspace: "in workspace",
          user: "",
          department: "in department",
          departments: "in department and subdepartments",
        },
      },
      status: {
        global: "Global",
        external: "",
        define: "To add",
        forbid: "To delete",
        active: "",
      },
    },
    roles: {
      admin: "Admin",
      editor: "Editor",
      members: "Users",
      employees: "Employees",
      user: "User",
      self: "User",
      creator: "Creator",
      not_specified: "Not specified",
    },
  },
  delete_modal: {
    delete_article: "Delete article",
    delete_section: "Delete section",
    delete_article_question: "Are you sure you want to delete the article",
    delete_section_question: "Are you sure you want to delete the section",
    delete_article_content:
      "The contents of the article, including images and files, will be lost forever!",
    delete_section_content:
      "The contents of the section, including images and files, will be lost forever!",
  },
  trash: {
    title: "Trash",
    alert: "Articles and sections can be restored from the Trash if deleted within the last 30 days. After 30 days, items in the Trash will be automatically and permanently deleted",
    articles: "Articles",
    sections: "Sections",
    headers: {
      deleted_at: "Deletion date",
      title: "Title"
    },
    restore_modal: {
      title: "Restore",
      body_articles: "Restore checked articles?",
      body_sections: "Restore checked sections? Articles of these sections will be restored too",
    }
},
  "Таблица обрабатывается, перезагрузите страницу позже":
    "The table is being processed, reload the page later",
  "Не удалось обработать таблицу": "Failed to process table",
  "Тип файла не соответствует разрешенным к загрузке":
    "The file type does not match the allowed uploads",
  "Максимальный размер файла": "Maximum file size",
  "Необходимо предоставить название": "Name is required",
  "Название должно содержать минимум 3 символа":
    "Name should be at least 3 characters long",
  "Содержимое статьи не может быть пустым":
    "Content of an article can't be empty",
  "Права применены на все подчинённые отделы":
    "Rights apply to all sub departments",
  "Права не применены на все подчинённые отделы":
    "Rights do not apply to all sub departments",
  "Применить на все подчинённые демаптаменты": "Apply to all sub departments",
  Подотделы: "Sub Departments",
  "Отдел...": "Department...",
  "Применить к подотделам": "Apply to Sub Departments",
  "Добавить информер": "Add informer",
  "Добавить контент": "Add content",
  Сниппеты: "Snippets",
  Информеры: "Informers",
  "Удалить выбранные": "Delete selected",
  "Переместить в раздел": "Move to a Section",
  "Убрать из избранного": "Remove from favorites",
  Переместить: "Move",
  "Что будет удалено:": "What's going to be deleted:",
  "Выберите раздел": "Select a Section",
  Контенты: "Contents",
  "База знаний": "Knowledge base",
  Поиск: "Search",
  Статьи: "Articles",
  Скрипты: "Surveys",
  Навигатор: "Navigator",
  "Нет подходящих пользователей": "There are no matching users",
  Разработка: "Development",
  "Доступен только мне": "Availible only to me",
  "Укажите ключ": "Please provide a key",
  "Ключ раздела": "Section key",
  "Выберите создателя": "Choose a creator",
  "IT-внедрения": "IT integrations",
  "Выберите категорию раздела. Можно несколько":
    "Choose a category. You can choose as much as you like",
  "Поиск по базе": "Search",
  Разделы: "Sections",
  Раздел: "Section",
  "Создать статью": "Create an article",
  "Создать раздел": "Create section",
  "Создание статьи": "Creating an article",
  "Создание раздела": "Creating Section",
  "Редактирование статьи": "Editing an article",
  "Редактирование раздела": "Section editing",
  "Редактировать раздел": "Edit section",
  Заголовок: "Heading",
  "Введите заголовок": "Enter a heading",
  "Введите текст здесь": "Enter text here",
  Опубликовать: "Publish",

  Название: "Title",
  Описание: "Description",
  "Ссылка скопирована": "Link copied",
  Поделиться: "Share",
  "Ответственный за статью сотрудник отвечает за предложения по изменению статьи":
    "The person responsible for the article is responsible for suggestions for changing the article",
  "Настройки доступа": "Access settings",
  "Настроить права доступа на редактирование": "Set edit permissions",
  "Выберите пользователя": "Select user",
  "Выберите группы пользователей": "Select user groups",
  Расширенный: "extended",
  "Поисковая выдача": " Search results",
  "Восстановить эту версию": "Restore this version",
  "Добавить в избранное": "Add to favorites",
  "Удалить из избранного": "Remove from Favorites",
  "Поиск по пользователям": "Search users",
  "Результаты поиска": "Searching results",
  "Роли пользователей": "User roles",
  "Написать комментарий": "Write a comment",
  "Введите сообщение": "Enter message",
  "Скрыть форму": "Hide form",
  Ответить: "Answer",
  Роль: "Role",
  "Управление пользователями": "Employees",
  "Пригласить пользователей": "Invite users",
  "В избранное": "Add favorite",
  "Это поле обязательное": "This field is required",
  "Выберите родительский раздел": "Choose parent section",
  "Вернуться к редактированию": "Back to editing",
  "подсказка по созданию статьи":
    'To create an article in a section, click the "Create article " button at the bottom of the left sidebar',
  "подсказка по созданию раздела":
    'To create a section, click "Create section", top right of the screen ',
  "Изменения вносил": "Changes made by",
  "Раздел не содержит подразделов и статей":
    "The section does not contain subsections and articles",
  "Предыдущие версии статьи не найдены":
    "No previous versions of article found",
  "Дата изменения": "Changed at",
  Страниц: "Pages",
  "Назначие прав пользователя": "Add user right",
  "Назначие прав депратаментов": "Add departments right",

  Отправить: "Send",
  "Найти статью или раздел": "Find an article or catagery",
  "История версий": "Versions history",
  "Ответственные за статью": "Responsible for article:",
  "Введите название": "Enter the title",
  "Начните вводить...": "Start typing...",

  "Ошибка отображения списка доступов": "Access list display error",
  "Ошибка сохранения": "Error with saving",
  Ошибка: "Error",
  "Недостаточно прав доступа или ресурс не существует":
    "Insufficient access rights or resource does not exist",
  "Введите текст здесь...": "Enter text here...",
  Код: "Code",
  Зеленый: "Green",
  Жирный: "Thick",
  "С пробелами": "Spaced",
  Обрамлённый: "Bordered",
  Заглавный: "Uppercase",
  Серый: "Grey",
  Прозрачный: "Transparent",
  Подсвеченный: "Highlighted",
  "Верхний уровень": "Upper level",
  "Убрать лайк": "Remove like",
  Нравится: "Like",
  Тег: "Tag",
  Теги: "Tags",
  "Тег не содержит привязанный статей": "Tag has no articles",
  "Права доступа": "Access rights",
  "Пользователь...": "User...",
  "Право доступа...": "Access...",
  "Создать подраздел": "Create subsection",
  "По умолчанию": "Default",
  "Полный доступ": "Full access",
  "Нет доступа": "Access denied",
  Просмотр: "View",
  Редактирование: "Edit",
  "Импорт из БЗ V16": "Import from KB V16",
  "Импорт из БЗ V16 кнопка": "Run ",
  "Импорт из БЗ V16 текст":
    " import articles and sections from version 16 of the Knowledge Base.",
  "Импорт из БЗ V16 текст 2":
    "ATTENTION! If something goes wrong, repeatedly running synchronization will not solve the problem...",
  "Импорт из БЗ V16 запущен": "Import started - it may take some time...",
  "У вас нет доступа на добавление раздела":
    "You do not have access to create a section",
  "Поделиться в Б24": "Share in Bitrix 24",
  "Статья будет опубликована в Живой ленте":
    "The article will be published in Bitrix Blog Post.",
  "Спасибо что поделились статьей": "Thank you for sharing the article!",
  "Ресурс не найден": "The resource can not be found",
  "Добавить статью": "Create an article",
  "Добавить раздел": "Create a section",
  Пользователи: "Users",
  Журналирование: "Logging",
  Инструменты: "Tools",
  "Панель администратора": "Administration",
  "Закрыть доступ всем": "Close access for everyone",
  "Название опроса": "Poll name",
  "Описание опроса": "Poll description",
  "Создать опрос": "Create poll",
  "Создание опроса": "Poll creating",
  "Введите описание": "Provide description",
  "Редактировать вопросы": "Edit questions",
  Вперёд: "Forward",
  "Создание вопросов": "Questions creating",
  Вопрос: "Question",
  "Введите формулировку": "Provide questions",
  "Вариант ответа": "Provide answer",
  "Формулировка варианта": "Option wording",
  "Показать ответ": "Show answer",
  "К следующему вопросу": "Next question",
  "Новый вопрос": "New question",
  "Сохранить вопросы": "Save question",
  "Формулировка вопроса": "Question wording",
  "Заполните все поля": "Fill all the fields",
  "Изменение вопросов для: ": "Question editing for: ",
  "Редактирование опроса": "Survey editing",
  subscribe: "Subscribe to update",
  unsubscribe: "Unsubscribe from update",
  email: "email",
  start_typing_title: "Start typing a name",
  no_matching_tags: "No matching tags found",
  sorting: "Sorting",
  sorting_description: "High value means high priority",
  sorting_range: "From 1 to 100",
  sorting_validation: "Sort must be set in the range of values from 1 to 100",
  enter_article_name: "Enter the title of the article",
  additionally: "Additionally",
  active_time: "Article active time",
  from: "from",
  to: "to",
  updated: "Updated",
  to_article: "Go to the article",
  to_section: "Go to the section",
  full_search: "Advanced search",
  workspace: {
    workspaces: "Workspaces",
    create_new: "Create a new workspace",
    title: "Title",
    description: "Short description",
    create: "Create",
    id: "ID",
    manage: "Manage workspaces",
    delete: "Delete",
    del_warning:
      "Are you sure? All articles from deleted workspace will be deleted too",
    edit: "Edit workspace",
    users: "Users",
    top_title: "Your workspaces",
    public_title: "Public knowledgebase",
    public_description: "Articles are in the public domain",
    workspace_attach: "Attach to workspace",
    workspace_detach: "Detach from workspace",
    tariff_restriction: "Your tariff does not allow to create more workspaces",
    show_tariffs: "Show tariffs",
  },

  familiarization_title: "Familiarization with the article",
  familiarization_description: "It is necessary to read the article",
  link_to_article: "Link to article",
  i_familiarize: "I have read!",
  familiarized: "Article is read",
  undo: "Undo",
  generation_started: "Generation started",
  restart: "Restart",
  need_to_familiarize: "the article is required to familiarize",
  familiarize_required: "Required to familiarize",
  familiarize: "Familiarize",
  clear_changes: "Clear changes",
  accept_selected: "Confirm choice",
  select_users: "Select employees",
  departments_title: "Departments",
  positions: "Positions",
  competences: "Competences",
  employees: "Employees",
  department_search: "Search by department",
  position_search: "Search by position",
  competence_search: "Search by competence",
  employee_search: "Search by employee",
  metrics: {
    settings: "Metrics settings",
    activity: "Activity",
    activity_description: "Setting for enabling metrics logging",
    threshold: "Threshold value",
    threshold_description:
      "Minimum page load time in seconds that should be logged",
    threshold_validate_range: "The value must be between 0 and 50",
  },
  main: "Main",
  new_articles: {
    title: "New articles",
    remove: "Remove all from new",
    remove_single: "Remove from new",
    remove_confirm:
      "Are you sure you want to remove the selected articles from the new?",
  },
  familiarize_page: {
    title: "List of required articles",
    is_empty: "List of articles to familiarize is empty",
  },
  favorites: {
    title: "Favorites",
    is_empty: "Favorite list is empty",
  },
  archive: {
    title: "Archive",
    is_empty: "Archive is empty",
  },
  drafts: {
    title: "Drafts",
    is_empty: "Drafts list is empty",
  },
  section_view: "View the section",
  article_view: "View the article",
  "snippet.content_view": "View the content",
  "snippet.informer_view": "View the informer",
  documents: "Documents",
  more: "More",
  share_url: {
    copy: "Copy link",
    copied: {
      title: "Copied",
      text: "The link copied to the clipboard",
    },
    share_b24: "Share in B24",
    hihub_url: "Direct link",
    bitrix_url: "Bitrix",
  },
  preview: "Preview",
  subsections: "Subsections",
  root_section_description:
    "List of root sections and articles without reference to a section",
  section_move_warning: "This section is marked for moving",
  enter_the_query: "Enter the query",
  popular_articles: "Popular articles",
  last_seen_articles: "Last seen articles",
  journal: "Journal",
  analytics:{
    header: "Analytics"
  },
  analyticsPage: {
    popularAuthors: {
      title: "Most published authors"
    },
    articlesPerPeriod: {
      number: "Number of created articles",
      title: "Number of created articles",
      week_number: "Week number",
      month_number: "Month",
      year_number: "Year"
    },
    most_viewed_articles: {
      title: "Top viewed articles",
      denied_article: "Access to this article is denied"
    },
    authorizations_per_period: {
      number: "Number of authorizations",
      title: "Number of authorizations",
    }
  },
  manageUsers: {

    changed_pass_success: "Passwords was changed without sending notifies",
    del_fail: "An error occurred while deleting users",
    del_success: "Deleting users is successfully finished",
    del_warning: "Are you sure that you want to delete selected users?",
    departments: "Departments",
    departments_null: "You haven't added a single department yet",
    departments_show: "Organizational structure",
    departments_show_head: "Head",
    departments_show_title: "Title",
    notifications: "Notifications",
    notifications_manual: "Manual notifications",
    notifications_password: "Generate Password",
    notifications_start: "Notification after the start of the rating",
    notifications_success: "Notifications were sent out",
    notifications_text: "If you click on the 'Generate Password' button, a new generated password will be sent to the user’s mail. After that, the previous user password will be invalid",
    no_notifications_password: "New password without a notification",
    positions: "Positions",

    delete: "Delete from HiHub"
  },
  familiarization_page: {
    manage_list: "Manage list",
    title: "To be read by"
  }
};
