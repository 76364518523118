import access from "./access";
import departments from "./departments";
import comments from "./comments";
import errors from "./errors";
import familiarization from "./familiarization";
import loading from "./loading";
import search from "./search";
import structure from "./structure";
import users_kb from "./users_kb";
import agGrid from "./agGrid";
import main_page from "./main_page";
import analytics from "./analytics";
import trashed from "./trashed";
import competence from "@hihub/vue-hihub-competences/src/store/competence";

/**
 * Vuex store accumulator
 *
 * Reexport to simplest use in main.js
 */

export default [
  {
    name: "access",
    module: access,
  },
  {
    name: "departments",
    module: departments,
  },
  {
    name: "comments",
    module: comments,
  },
  {
    name: "errors",
    module: errors,
  },
  {
    name: "familiarization",
    module: familiarization,
  },
  {
    name: "search",
    module: search,
  },
  {
    name: "structure",
    module: structure,
  },
  {
    name: "users_kb",
    module: users_kb,
  },
  {
    name: "loading",
    module: loading,
  },
  {
    name: "agGrid",
    module: agGrid,
  },
  {
    name: "main_page",
    module: main_page,
  },
  {
    name: "analytics",
    module: analytics
  },
  {
    name: "trashed",
    module: trashed
  },
  {
    name: "competence",
    module: competence
  }
];
