import Vue from "vue";
import correctDateAccordingWithTimezone from "@hihub/vue-hihub-ui-core/src/core/services/helpers/timezone-fix.js";

const familiarization = {
  namespaced: true,
  state: {
    list: [],
    loading: false,
    loaded: false,
    tasks: {},
    taskListDispatchCounter: 0,
    lastDispatchId: 0,
  },
  getters: {
    loaded: (state) => state.loaded,
    loading: (state) => state.loading,
    hasArticles: (state) => {
      return state.list.filter(item => !item.completed).length;
    },
    list: (state) => state.list.filter(item => !item.completed),
    getByArticleId: (state) => (id) => {
      return state.list.find((item) => Number(item.article_id) === Number(id));
    },
    getTasksByArticleId: (state) => (id) => state.tasks[id],
    getTaskListDispatchCounter: (state) => (id) => state.taskListDispatchCounter,
  },
  mutations: {
    SET_LOADED(state) {
      state.loaded = true;
    },
    SET_LOADING(state, value) {
      state.loading = value;
    },
    SET_LIST(state, data) {
      Vue.set(state, "list", Object.values(data));
    },
    UNCLOSE_TASK_IN_LIST(state, data) {
      state.list.forEach(item => {
        if(item.task_id == data.task_id){
          item.completed = false;
        }
      });
    },
    CLOSE_TASK_BY_ID(state, taskId) {
      state.list.forEach(item => {
        if(item.task_id == taskId){
          item.completed = true;
        }
      });
    },
    SET_LOADING_STATUS_FOR_TASK(state, { taskId, status }) {
      state.list.find(
        (item) => Number(item.task_id) === Number(taskId)
      ).loading = status;
    },
    SET_USERS_TASKS(state, data) {
      Vue.set(state.tasks, data.id, {"data": data.data, "pagination": data.pagination});
    },
    UPDATE_TASK_LIST_DISPATCH_COUNTER(state, data){
      let val = Number(data);
      let newVal = state.taskListDispatchCounter + val;
      Vue.set(state, "taskListDispatchCounter", newVal);
    },
  },
  actions: {
    load(context) {
      if (context.state.loaded) return;

      context.commit("SET_LOADING", true);
      const request = this.$app.$api.tasks.load(); //'article_id', 'task_id', 'article_name', 'completed' by user_id
      request
        .then((response) => {
          context.commit("SET_LIST", response.data);
        })
        .finally(() => {
          context.commit("SET_LOADING", false);
          context.commit("SET_LOADED");
        });
    },
    hasTemplate({commit, state}, {article_id}){
      const request = this.$app.$api.tasks.hasTemplate(article_id);
      return request;
    },
    getTemplate({commit, state}, {article_id}){
      const request = this.$app.$api.tasks.getTemplate(article_id);
      return request;
    },
    closeTask(context, {task_id}){
      const request = this.$app.$api.tasks.close(task_id);
      request.then((resp) => {
        context.commit("CLOSE_TASK_BY_ID", task_id);
      });

      return request;
    },
    uncloseTask(context, {task_id}){
      const request = this.$app.$api.tasks.unclose(task_id);
      request.then((resp) => {
        context.commit("UNCLOSE_TASK_IN_LIST", resp.data);
      });

      return request;
    },
    createTemplate({commit, state}, {articleId, data}){
      const request = this.$app.$api.tasks.createTemplate(articleId, data);
      return request;
    },
    rerunTask({commit, state}, { articleTemplate, data }){
      const request = this.$app.$api.tasks.rerunTasks(articleTemplate, data);
      return request;
    },
    loadUsersTasksByArticleId({commit, state}, data){
      let sort = [];
      if(data.sort){
        sort.push({ [data.sort.sort_column] : data.sort.sort_type});
      } else {
        sort = [
          {"created_at_table": {"order": "desc"}}
        ];
      }
      let page = data.page || 1;
      let query = data.query || "";
      let filter = data.filter || [];
      commit("UPDATE_TASK_LIST_DISPATCH_COUNTER", 1);
      state.lastDispatchId++;
      let dispatchId = state.lastDispatchId;
      return this.$app.$api.tasks.load_users_tasks({id: data.entityId, page: page, per_page: 10, search: query, sort: sort, filter: filter})
        .then((response) => {
          if(state.lastDispatchId == dispatchId){
            let pagination = {};
            for(let index in response.data){
                if(index != "data"){
                    pagination[index] = response.data[index];
                }
            }
            let resultData = response.data.data ? response.data.data : {};
            //transform dates according with timezone
            resultData.forEach(row => {
              if(row.hasOwnProperty("completed_at_table")){
                if(row.completed_at_table.value){
                  row.completed_at_table.value = correctDateAccordingWithTimezone(row.completed_at_table.value);
                }
              }
              if(row.hasOwnProperty("additions")){
                if(row.additions.completed_at){
                  row.additions.completed_at = correctDateAccordingWithTimezone(row.additions.completed_at);
                }
              }
            });
            commit("SET_USERS_TASKS", {"id": data.entityId, "data": resultData, "pagination": pagination});
          }
        })
        .finally(() => {
          commit("UPDATE_TASK_LIST_DISPATCH_COUNTER", -1);
        });
    }
  },
};

export default familiarization;
